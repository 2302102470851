import React from 'react'
import { footerText } from '../data/footert'
import { createMarkup } from '../funcs/createMp'

const FooterText = ({ promocode, lang }) => {
  return (
    <section className='mainCnt'>
      <div className='container py-6'>
        <span
          className='leading-6'
          dangerouslySetInnerHTML={createMarkup(
            footerText(promocode)[lang].text
          )}
        />
      </div>
    </section>
  )
}

export default FooterText

 const notfound = {
   title:[
      {
         id:1,
         locale:'uz',
         text:`<span>Promo-kod topilmadi</span>`

      },
      {
         id:2,
         locale:'fr',
         text:`<span>Aucun code promo trouvé</span>`

      },
      {
         id:3,
         locale:'en',
         text:`<span>Promo code not found</span>`

      },
      {
         id:4,
         locale:'so',
         text:`<span>Promokodka lama heli karo</span>`

      },
      {
         id:5,
         locale:'ar',
         text:`<span class='directionRtl'>لم يتم العثور على رمز ترويجي</span>`

      },
      {
         id:6,
         locale:'ru',
         text:`<span>Промокод не найден</span>`,
         
      },
      {
         id:7,
         locale:'pt',
         text:`<span>Código promocional não encontrado</span>`


      },
      {
         id:8,
         locale:'bd',
         text:`<span>প্রমোকোড পাওয়া যায়নি</span>`


      }
      
   ],
   desc:[
      {
         id:1,
         locale:'uz',
         text:`<span>Kiritilgan ma'lumotlarning to'g'riligini tekshiring</span>`
      },
      {
         id:2,
         locale:'fr',
         text:`<span>Vérifiez que les informations que vous avez saisies sont correctes.</span>`

      },
      {
         id:3,
         locale:'en',
         text:`<span>Check if the entered information is correct</span>`

      },
      {
         id:4,
         locale:'so',
         text:`<span>Iska hubi macluumaadka aad galisay</span>`

      },
      {
         id:5,
         locale:'ar',
         text:`<span class='directionRtl'>تأكد من صحة المعلومات التي أدخلتها</span>`

      },
      {
         id:6,
         locale:'ru',
         text:`<span>Проверьте правильность введенной информации</span>`
         
      },
      {
         id:7,
         locale:'pt',
         text:`<span>Verifique se as informações inseridas estão corretas</span>`

      },
      {
         id:8,
         locale:'bd',
         text:`<span>প্রবেশ করা তথ্যটি  সঠিক কিনা তা পরীক্ষা করুন</span>`

      }
      
   ]
}


export default notfound
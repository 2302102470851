import React from 'react'
import BonusInp from './BonusInp'
const Bonus = ({locale,hreff,promoCode}) => {
   return (
      <section id='bonus' className={`container mtop`}>
         
         <div className={`xl:px-[50px] md:px-[40px] px-[32px] rounded-[40px] overflow-hidden bg-[var(--green)] md:pb-[87px] pb-[310px] md:pt-[87px] pt-[32px] relative`}>
            <div className=' w-full h-full absolute top-0 left-0 overflow-hidden rounded-[40px]'>
                  <img className=' bgImg bgImgBon'  src="/img/bbg.png" alt="bg" />
                  <div className={`xl:w-[350px] xl:h-[350px] w-[280px] h-[280px] absolute z-[2] xl:top-[20px] md:top-[160px] md:bottom-auto  bottom-[-80px] top-auto xl:right-[100px] md:right-[20px] right-[80px] bonusImgT`}>
                     <img alt={'bonus'} className='absolute' src={'/img/bon1.png'}/>

                  </div>
                  <div className={`absolute z-[2] xl:top-[-50px] md:top-[50px] top-auto md:bottom-auto bottom-[-100px] md:right-[-200px] right-[-150px] xl:w-[496px] xl:h-[496px]  w-[390px] h-[390px] bonusImgR`}>
                     <img alt={'bonus'}  className='absolute' src={'/img/bon2.png'}/>

                  </div>
                  
            </div>
            <BonusInp promoCode={promoCode} hreff={hreff} locale={locale}/>
         </div>
      </section>
   )
}

export default Bonus

 const pays = [
   {
      id:1,
      name:'all',
      imgs:['/img/pays/all/visa.svg','/img/pays/all/mastercard.svg','/img/pays/all/webmoney.svg','/img/pays/all/gpay.svg']
   },
   {
      id:2,
      name:'bd',
      imgs:['/img/pays/bang/bcash.svg','/img/pays/bang/nogod.svg','/img/pays/bang/rocket.svg','/img/pays/bang/pay.svg','/img/pays/bang/visa.svg','/img/pays/bang/mastercard.svg']

   },
   // {
   //    id:3,
   //    name:'en',
   //    imgs:['/img/pays/all/visa.svg','/img/pays/all/mastercard.svg','/img/pays/all/webmoney.svg','/img/pays/all/gpay.svg']

   // },
   {
      id:4,
      name:'uz',
      imgs:['/img/pays/uz/humo.svg','/img/pays/uz/uzcard.svg','/img/pays/uz/visa.svg','/img/pays/uz/mastercard.svg']

   },
   // {
   //    id:5,
   //    name:'ar',
   //    imgs:['/img/pays/all/visa.svg','/img/pays/all/mastercard.svg','/img/pays/all/webmoney.svg','/img/pays/all/gpay.svg']

   // },
   {
      id:6,
      name:'ru',
      imgs:['/img/pays/rus/visa.svg','/img/pays/rus/mastercard.svg','/img/pays/rus/mir.svg','/img/pays/rus/webmoney.svg','/img/pays/rus/gpay.svg']

   },
   {
      id:7,
      name:'sn',
      imgs:['/img/pays/senegal/or.svg','/img/pays/senegal/fm.svg']

   },
   {
      id:8,
      name:'ci',
      imgs:['/img/pays/coted/or.svg','/img/pays/coted/mtn.svg','/img/pays/coted/mm.svg']

   },
   {
      id:9,
      name:'gn',
      imgs:['/img/pays/guinee/or.svg','/img/pays/guinee/mtn.svg']

   },
   {
      id:10,
      name:'bj',
      imgs:['/img/pays/benin/mm.svg','/img/pays/benin/mtn.svg']

   },
   {
      id:12,
      name:'ga',
      imgs:['/img/pays/gabon/moov.svg','/img/pays/gabon/airtel.svg']

   },
   {
      id:13,
      name:'cm',
      imgs:['/img/pays/camer/mtn.svg','/img/pays/camer/or.svg']

   },
   {
      id:14,
      name:'ml',
      imgs:['/img/pays/mali/or.svg','/img/pays/mali/moov.svg']

   },
   {
      id:15,
      name:'cz',
      imgs:['/img/pays/rdc/airtel.svg','/img/pays/rdc/or.svg','/img/pays/rdc/mpesa.svg']

   },
   {
      id:16,
      name:'cd',
      imgs:['/img/pays/congo/airtel.svg','/img/pays/congo/or.svg','/img/pays/congo/r.svg','/img/pays/congo/mtn.svg']

   },
   {
      id:17,
      name:'bf',
      imgs:['/img/pays/burk/or.svg','/img/pays/burk/moov.svg']

   },
   {
      id:18,
      name:'ke',
      imgs:['/img/pays/kenya/mpesa.svg','/img/pays/kenya/airtel.svg','/img/pays/kenya/telkom.svg']

   },
   {
      id:19,
      name:'tn',
      imgs:['/img/pays/tunisia/d17.svg']

   },
   // {
   //    id:20,
   //    name:'dz',
   //    imgs:['/img/pays/all/visa.svg','/img/pays/all/mastercard.svg','/img/pays/all/webmoney.svg','/img/pays/all/gpay.svg']

   // },
   // {
   //    id:21,
   //    name:'pk',
   //    imgs:['/img/pays/all/visa.svg','/img/pays/all/mastercard.svg','/img/pays/all/webmoney.svg','/img/pays/all/gpay.svg']

   // }
   
]



export default pays

export default function genereteMetaTags (locale = "", promocode = "") {
    switch (locale) {
        case "uz" : {
            return {
                title : `Linebet: promo-kod ${promocode} bonus olish uchun`,
                description : ` Linebet: reklama kodini kiriting ${promocode} Linebet.com rasmiy veb-saytida o'ting va bonus oling. Mobil ilovani yuklab oling va turli sport turlariga pul tiking.`
            }
        }
        case "en" : {
            return {
                title : ` Linebet: promo code ${promocode}  to get bonus`,
                description : `Enter ${promocode} promo code on Linebet.com official site and get your bonus. Download mobile app and place bets on different kinds of sports.`
            }
        }
        case "bd" : {
            return {
                title : `লাইনবেট: প্রমো কোড ${promocode} বোনাস পাওয়ার জন্য`,
                description : `Linebet: অফিসিয়াল সাইট Linebet.com-এ ${promocode} প্রোমো কোড লিখুন এবং বোনাস সংগ্রহ করুন। মোবাইল অ্যাপ্লিকেশন ডাউনলোড করুন এবং বিভিন্ন খেলায় বাজি ধরুন।`    
            }
        }
        case  "ar" :  {
            return {
                title : `
 لينيبت برومو كود ${promocode} للحصول على بونص`,
description : ` أدخل برومو كود على موقع Linebet.com الرسمي واحصل على بونصك. قم بتحميل التطبيق المحمول وضع رهانات على أنواع مختلفة من الرياضات.
`
            }
        }
        case "ru" : {
            return {
                title : `Linebet: промокод ${promocode} для получения бонуса`,
                description : `Linebet: введите промокод ${promocode} на официальном сайте Linebet.com и заберите бонус. Качайте мобильное приложение и делайте ставки на разные виды спорта.
`
            }
        }
        case  "pt" : {
            return {
                title : ` Linebet: código promocional ${promocode} para obter o bônus`, 
                description : `Linebet: insira o código promocional do ${promocode} no site oficial Linebet.com e fique com o bónus. Baixe o aplicativo móvel e aposte em vários esportes.
`
            }
        }
        case "fr" : {
            return {
                title : `Linebet : code promo ${promocode} pour recevoir un bonus`,
                description : ` Linebet : entrez le code promotionnel ${promocode} sur le site officiel Linebet.com et recevez un bonus. Téléchargez l'application mobile et pariez sur différents sports.`
            }
        }
        default : {
            return {
                title : ` Linebet: promo code ${promocode}  to get bonus`,
                description : `Enter ${promocode} promo code on Linebet.com official site and get your bonus. Download mobile app and place bets on different kinds of sports.`
            }
        }
    }
} 
import React from 'react'

const Loader = ({loading,langsCh}) => {
   return (
      <div className={`loader 4xl:text-[92px] text-[7vw] font-[500] text-[var(--green)] fixed h-[150vh] pb-[50vh] flex items-center justify-center z-[10] ${!loading?' transition-opacity duration-500 opacity-0 pointer-events-none':'active'} ${langsCh&&'langsCh'} bg-white top-0 left-0 right-0`}>
         
         <div className={`loaderW bg-white z-[10]`}>
            L
         </div>
         <div className={`loaderW bg-white z-[9]`}>
            I
         </div>
         <div className={`loaderW bg-white z-[8]`}>
            N
         </div>
         <div className={`loaderW bg-white z-[7]`}>
            E
         </div>
         <div className='loaderW bg-white z-[6] flex items-center justify-start'>

            <div className='loaderW  bg-white text-[var(--black)] '>
               B
            </div>
            <div className='loaderW  bg-white text-[var(--black)] '>
               E
            </div>
            <div className='loaderW  bg-white text-[var(--black)] '>
               T
            </div>
         </div>

      </div>
   )
}

export default Loader
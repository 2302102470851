import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import './App.scss'
import Bonus from './components/bonus/Bonus'
import langs from './components/data/langs'
import Footer from './components/footer/Footer'
import FooterText from './components/footerText/FooterText.tsx'
import Header from './components/header/Header'
import Slider from './components/slider/Slider'
import Welcome from './components/welcome/Welcome'
import genereteMetaTags from './components/data/genereteMeta.js'

function getLocaleFromBrowser()
{
  const localeBrowser = window.navigator.language.slice(0, 2);
  const urlLang = new URL(window.location).pathname.slice(1, 3);
  const langlist = langs.map(el => el.name);
  const storage = localStorage.getItem("lang");
  if (langlist.includes(urlLang)) {
    return urlLang;
  } else if(!urlLang && (storage && langlist.includes(storage))) {
    return storage
  }
  else if (!langlist.includes(urlLang) && !storage && langlist.includes(localeBrowser)) {
    return localeBrowser;
  } else {
    return "en"
  }
}



const App = () =>
{
  const [locale, setLocale] = useState('en')
  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState(true)

  const [promoCode, setPromoCode] = useState('')

  const [hreff, setHreff] = useState('')

  const [mess, setMess] = useState('')

  useEffect(() =>
  {
    const domain = window.location.origin;
    let lang =  getLocaleFromBrowser();
    setPromoCode(domain.split('.')[0])
    if (!lang || !langs.map((lang) => lang.name).includes(lang)) {
      window.localStorage.setItem('lang', getLocaleFromBrowser());
    }

    if (lang && langs.map((lang) => lang.name).includes(lang)) {
      localStorage.setItem('lang', lang)
      setLocale(lang)
      window.history.pushState({}, "", new URL(`/${lang}`, new URL(domain)))
    }

    fetch(`https://lineorgs.com/api.php?promo=${domain.split('.')[0]}`)
      .then((res) => res.json())
      .then((result) =>
      {
        if (!result.is_success) {
          setNotFound(true)
        } else {
          setNotFound(false)
          if (result.data) {
            setHreff(result.data.href)
            setMess(result.data.message)
          }
        }
        setTimeout(() =>
        {
          setLoading(false)
        }, 1600)
      })
      .catch((err) =>
      {
        console.log(err)
        setNotFound(true)
        setLoading(false)
      })
  }, [])



  return (
    <>
      <Helmet>
        <title>{genereteMetaTags(locale, promoCode).title}</title>
        <meta
          id='description'
          name='description'
          content={genereteMetaTags(locale, promoCode).description}
        />
        <meta
          id='meta-title'
          property='og:title'
          content={genereteMetaTags(locale, promoCode).title}
        />
        <meta
          id='meta-description'
          property='og:description'
          content={genereteMetaTags(locale, promoCode).description}
        />
      </Helmet>
      <Header
        hreff={hreff}
        mess={mess}
        setLoading={setLoading}
        loading={loading}
        locale={locale}
        setLocale={setLocale}
        promocode={promoCode}
      />
      <main className='main '>
        <section className={` mainCnt`}>
          <Welcome locale={locale} notFound={notFound} />

          {!notFound && (
            <>
              <Slider locale={locale} />
              <Bonus promoCode={promoCode} hreff={hreff} locale={locale} />
            </>
          )}
        </section>
        {!notFound && <FooterText promocode={promoCode} lang={locale} />}
      </main>
      <Footer hreff={hreff} />
    </>
  )
}

export default App

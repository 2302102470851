import React, { useEffect, useState } from 'react'
import Loader from '../loader/Loader'
import Menu from './Menu'

const Header = ({
  locale,
  setLocale,
  setLoading,
  loading,
  mess,
  hreff,
  promocode,
}) => {
  const [langsCh, setLangsCh] = useState(false)
  const [load, setLoad] = useState(false)
  useEffect(() => {
    if (!loading && !load) {
      setLoad(true)
    }
  }, [loading])

  return (
    <header className='mainCnt'>
      <Loader loading={loading} langsCh={langsCh} />
      <div
        className={` container 4xl:pt-[30px] 4xl:pb-[40px] md:pt-[36px] xl:pb-[46px] md:pb-[36px] pt-[41px] pb-[41px]`}
      >
        <div className='flex relative items-center md:justify-center w-full'>
          <div>
            <a
              href={hreff}
              className={`block 4xl:h-[67px] 4xl:w-[219px]  xl:w-[174px] xl:h-[55px]  md:w-[128px] md:h-[40px] w-[93px] h-[30px]    relative`}
            >
              <img className='absolute ' src='/img/logo.svg' alt='linebet' />
            </a>
          </div>
          <div
            className={`z-[5] flex items-center justify-center absolute top-1/2 -translate-y-1/2 right-0`}
          >
            <a
              className={`block relative xl:h-[43px] xl:w-[143px] md:h-[28px] md:w-[93px] h-[34px] w-[113px] xl:mr-[30px] md:mr-[25px] mr-[16px]`}
              href={mess}
            >
              <img className='absolute ' src='/img/app.svg' alt='linebet' />
            </a>
            {load && (
              <Menu
                promocode={promocode}
                setLoading={setLoading}
                setLangsCh={setLangsCh}
                locale={locale}
                setLocale={setLocale}
              />
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

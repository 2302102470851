import React, { useRef, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
const Fly = ({ wid, hei, src, delay }) => {
  const refI = useRef(null)
  const [topLeft, setTopLeft] = useState({
    top: (hei + 100) / 2,
    left: (wid + 100) / 2,
  })
  const [timeSt, setTimeSt] = useState(0)
  const desk = useWindowSize()
  const focusIt = (e) => {
    if (desk.desk) {
      let coords = e.target.getBoundingClientRect()
      let y = Math.round(coords.top)
      let x = Math.round(coords.left)
      let h = hei + 100
      let w = wid + 100
      let l = (wid + 100) / 2
      let t = (hei + 100) / 2
      l = e.pageX - x
      t = e.pageY - y
      if (e.pageX - x > wid) {
        l = (wid + 100) / 2 + wid / 4
      } else {
        l = (wid + 100) / 2 - wid / 4
      }
      if (e.pageY - y > hei) {
        t = (hei + 100) / 2 + hei / 4
      } else {
        t = (hei + 100) / 2 - hei / 4
      }
      let ts = e.timeStamp
      if (ts - timeSt > 500) {
        setTimeSt(ts)
        setTopLeft({ top: t, left: l })
      }
    }
  }

  const focusOut = () => {
    setTopLeft({ top: (hei + 100) / 2, left: (wid + 100) / 2 })
  }
  return (
    <div
      onMouseLeave={focusOut}
      onMouseMove={focusIt}
      style={{ width: wid + 100 + 'px', height: hei + 100 + 'px' }}
      className={` overflow-visible flex items-center justify-center `}
    >
      <img
        style={{
          animationDelay: delay,
          left: topLeft.left + 'px',
          top: topLeft.top + 'px',
        }}
        ref={refI}
        className=' pointer-events-none bgImgFly absolute '
        src={src}
        alt='bonus'
      />
    </div>
  )
}

export default Fly

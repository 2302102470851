import React, { useRef, useState } from 'react'
import bonus from '../data/bonus'
const BonusInp = ({ locale, hreff, promoCode }) => {
  const [copyed, setCopyed] = useState(false)
  const bonusRef = useRef(null)

  const copyClick = () => {
    if (!copyed) {
      if (bonusRef.current) {
        let c = bonusRef.current.textContent
        window.navigator.clipboard.writeText(c)

        setCopyed(true)
        setTimeout(() => {
          setCopyed(false)
        }, 2000)
      }
    }
  }
  return (
    <div
      className={`flex-wrap relative z-[3] flex xl:items-center items-start xl:flex-row flex-col`}
    >
      <div
        onClick={copyClick}
        className={`cursor-pointer relative xl:mr-[30px] xl:mb-0 mb-[25px] 4xl:px-[60px] xl:px-[50px] h-[80px] px-[20px] flex items-center justify-between xl:w-fit md:w-[70%] w-full max-w-full overflow-hidden py-[10px] ${
          !copyed ? `border-dashed` : 'border-solid'
        } border-white border-[2px] rounded-[20px] font-[800] 4xl:text-[48px] xl:text-[42px] md:text-[36px] text-[6vw] text-white`}
      >
        <p
          ref={bonusRef}
          className={`lineorg-slider__main_promo flex-auto xl:mr-[50px]  mr-[20px] `}
        >
          {promoCode}
        </p>
        <div
          className={`flex items-center justify-center absolute text-[var(--green)] font-[400] text-[20px] w-full h-full top-0 left-0 bg-white z-[1] transition-opacity ${
            !copyed && `opacity-0 pointer-events-none`
          }`}
        >
          <img className='mr-[10px] ' src='/img/ok.svg' alt='copy' />
          {bonus.copy.find((item) => item.locale === locale)?.text}
        </div>
        <button className=' flex-none '>
          <img
            className='sm:max-w-none max-w-[20px]'
            src='/img/copy.svg'
            alt='copy'
          />
        </button>
      </div>
      <a
        href={hreff ?? ''}
        className={`xl:mr-7 lineorg-slider__main_bonus h-[80px] relative z-[3] font-[500] flex items-center justify-center btn btnBonus 4xl:text-[28px] xl:text-[24px] md:text-[20px] text-[16px] xl:w-fit md:w-[70%] w-full text-[var(--green)]`}
      >
        {bonus.btn.find((item) => item.locale === locale)?.text}
      </a>
      <div className='md:w-[70%] text-white flex-1 mt-3 basis-[700px]'>
        <p className='mb-1'>
          {bonus.text.find((item) => item.locale === locale).title +
            ' ' +
            promoCode}
        </p>
        <p>
          {bonus.text.find((item) => item.locale === locale).text +
            ' ' +
            promoCode +
            ' ' +
            bonus.text.find((item) => item.locale === locale).text2}
        </p>
      </div>
    </div>
  )
}

export default BonusInp

const bonus = {
  copy: [
    {
      id: 1,
      locale: 'uz',
      text: `Nusxalangan`,
    },
    {
      id: 2,
      locale: 'fr',
      text: `Copié`,
    },
    {
      id: 3,
      locale: 'en',
      text: `Copied`,
    },
    {
      id: 4,
      locale: 'so',
      text: `Naqliyay`,
    },
    {
      id: 5,
      locale: 'ar',
      text: `نسخ`,
    },
    {
      id: 6,
      locale: 'ru',
      text: `Скопировано`,
    },
    {
      id: 7,
      locale: 'pt',
      text: `Copiado`,
    },
    {
      id: 8,
      locale: 'bd',
      text: `কপি করা হয়েছে।`,
    },
  ],

  btn: [
    {
      id: 1,
      locale: 'uz',
      text: `Bonus olish`,
    },
    {
      id: 2,
      locale: 'fr',
      text: `Recevez un bonus`,
    },
    {
      id: 3,
      locale: 'en',
      text: `Get bonus`,
    },
    {
      id: 4,
      locale: 'so',
      text: `Faaido hel`,
    },
    {
      id: 5,
      locale: 'ar',
      text: `احصل على مكافأة`,
    },
    {
      id: 6,
      locale: 'ru',
      text: `Получить бонус`,
    },
    {
      id: 7,
      locale: 'pt',
      text: `Obter bônus`,
    },
    {
      id: 8,
      locale: 'bd',
      text: `প্রমোকোড ব্যবহার করে বোনাস বুঝে নিন।`,
    },
  ],
  text: [
    {
      id: 1,
      locale: 'uz',
      title: 'Linebet - promo-kodlar yordamida bonuslar va aktsiyalar',
      text: `Linebetning foydali taklifi haqida o'qing: promo-kodni kiriting`,
      text2: ` sport va kibersportlarga tikish uchun 100$ gacha bonusga ega bo'ling.`,
    },
    {
      id: 2,
      locale: 'fr',
      
      title: 'Linebet : code promo  pour recevoir un bonus',
      text: `code promo  pour recevoir jusqu'à 100$ de bonus`,
      text2: `obtenez jusqu'à 100$ en utilisant le code promo gmtwin pour parier sur le sport et l'e-sport.`,
    },
    {
      id: 3,
      locale: 'en',
      title: 'Linebet - bonuses and offers with promo code',
      text: `Linebet: bonus up to 100$ with promo code`,
      text2: `- place bets in mobile app and on the official site Linebet.com `,
    },
    {
      id: 4,
      locale: 'so',
      title: 'Linebet - бонусы и акции по промокоду',
      text: `Читайте о выгодном предложении Linebet: введите промокод`,
      text2: `и получите бонус до 100$ на ставки на спорт и киберспорт.`,
    },
    {
      id: 5,
      locale: 'ar',
      title: 'لاين بت - المكافآت والعروض بالرمز الترويجي ',
      text: `
الوصف: اقرأ عن العرض المميز من لاين بت: أدخل الرمز الترويجي
`,
      text2: `واحصل على مكافأة تصل إلى 100 دولار على الرهانات على الرياضة والرياضات الإلكترونية`,
    },
    {
      id: 6,
      locale: 'ru',
      title: 'Linebet - бонусы и акции по промокоду',
      text: `Читайте о выгодном предложении Linebet: введите промокод`,
      text2: `и получите бонус до 100$ на ставки на спорт и киберспорт.`,
    },
    {
      id: 7,
      locale: 'pt',
      title: 'Linebet - des bonus et des promotions avec le code promotionnel',
      text: `Découvrez les avantageuses offres de Linebet : entrez le code promotionnel `,
      text2: `иet obtenez un bonus allant jusqu'à 100$ sur les paris sportifs et esports`,
    },
    {
      id: 8,
      locale: 'bd',
      title: ' Linebet এর লাভজনক অফার সম্পর্কে পড়ুন: প্রোমো কোড  লিখুন এবং স্পোর্টস ',
      text: `শিরোনাম: লাইনবেট - প্রোমোশন কোড  বোনাস এবং অ্যাকশন`,
      text2: `এবং ই-স্পোর্টস বেটে 100$ পর্যন্ত বোনাস পান।`,
    },
  ],
}

export default bonus
